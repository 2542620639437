/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LCDevBar
 */
import React from 'react';
import { titleCase } from '@lifechurch/web-tools-io/dist/utils/helpers/titleCase';
import './LCDevBar.scss';

/**
 * Represents a simple info toolbar useful for development to allow a user to see environment-specific values used for the site.
 *
 * @returns {React.ReactElement} - The LCDevBar component.
 */
export default function LCDevBar() {
  const [envSettings, setEnvSettings] = React.useState({});
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  /**
   * Handler function for collapse state toggle.
   */
  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  /**
   * Single-run convenience effect to set ENV settings state.
   */
  React.useEffect(() => {
    setEnvSettings((originalSettings) => {
      return {
        ...originalSettings,
        Environment: process.env.NODE_ENV,
        'Magnolia CMS': process.env.REACT_APP_CMS,
        'Magnolia Host': process.env.REACT_APP_MGNL_HOST,
      };
    });
  }, []);

  return (
    <div className="lc-dev-bar">
      <div className="container">
        <button
          className={`btn-expand icon ${
            isCollapsed ? 'icon-angle-up' : 'icon-angle-down'
          }`}
          onClick={toggleCollapsed}
        >
          <span className="label">Website Settings</span>
        </button>
        <div className="content">
          <h3 className="border-bottom">
            Website Environment Settings
            <div
              className={`info-tooltip${!isCollapsed ? ' expanded' : ''}`}
              onClick={toggleCollapsed}
            >
              i
              <span className="tooltip-text">
                <b>Note:</b> This toolbar is only present on non-production
                versions of the website.
              </span>
            </div>
          </h3>
          <ul>
            {Object.entries(envSettings).map(([settingKey, settingValue]) => {
              return (
                <li key={settingKey.toLowerCase()}>
                  <b>{settingKey}:</b>{' '}
                  {settingKey === 'Environment'
                    ? titleCase(settingValue)
                    : settingValue}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
